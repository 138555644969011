export const TRANSLATIONS_IT = {
  "occasion": "occasione",
  "You don’t have permissions to take a photo": "Non si hanno i permessi per scattare una foto",
  "No internet connection": "Nessuna connessione a Internet",
  "Something went wrong": "Qualcosa è andato storto",
  "No services available": "Nessun servizio disponibile",
  "Password must be at least 8 characters": "La password deve essere lunga almeno 8 caratteri e contenere almeno 1 lettera maiuscola e 1 minuscola, 1 simbolo, 1 cifra e senza spazi bianchi.",
  "Service registration is closed": "La registrazione al servizio è chiusa.",
  "New password does not meet security requirements": "La nuova password non soddisfa i requisiti di sicurezza",
  "You can't remove this temporal item": "Non è possibile rimuovere questo elemento temporale",
  "add occasion": "aggiungere un'occasione",
  "Occasion": "Occasione",
  "Optional": "Opzionale",
  "Flight reopened": "Vuelo reabierto",
  "This field is mandatory": "Questo campo è obbligatorio",
  "success": "successo",
  "Flight registration is closed": "El registro de vuelos está cerrado.",
  "validating": "convalida di",
  "reopen flight": "riaprire il volo",
  "close the flight": "chiudere il volo",
  "finalization": "finalizzazione",
  "approved": "approvato",
  "failed": "fallito",
  "validation": "convalida",
  "Photo registration": "Registrazione delle foto",
  "In order to close this flight you need to provide": "Per chiudere questo volo è necessario fornire dettagli obbligatori.",
  "Photo types required": "Tipi di foto richiesti",
  "Something wrong": "Qualcosa di sbagliato",
  "or": "o",
  "Receive job vacancies": "Ricevere le offerte di lavoro",
  "Job vacancy": "Posto vacante",
  "Go to flights": "Vai ai voli",
  "Page not found": "Pagina non trovata",
  "Please click here to proceed to flights list": "Cliccare qui per procedere alla lista dei voli",
  "Your session has expired. Please sign in again.": "La tua sessione è scaduta. Per favore, accedi di nuovo.",
  "Timestamps type": "Tipo di timestamps",
  "You were signed out automatically": "Sei stato disconnesso automaticamente. Il token è scaduto. Puoi riaccedere con le tue credenziali",
  "Are you sure you want to sign out?": "Sei sicuro di voler firmare?",
  "is signed in": "è firmato in",
  "Sign in": "Accedi",
  "Registration closed": "Registrazione chiusa",
  "Close registration": "Chiudere la registrazione",
  "Dummy Type": "Tipo di manichino",
  "Aircraft Damage": "Danni all'aeromobile",
  "No types here yet": "Nessun tipo qui ancora",
  "Select type": "Selezionare il tipo",
  "Please briefly describe where you found the item": "Per favore, descrivi brevemente dove hai trovato l'articolo. Specificare la stanza o la linea e la fila se disponibile",
  "type": "tipo",
  "Describe location": "Descrivere la posizione",
  "Please pick on scheme": "Si prega di scegliere lo schema",
  "Cones": "Coni",
  "Chocks": "Strozzatori",
  "Cannot set a future time.": "Non è possibile impostare un orario futuro.",
  "This section is used to deal with visible damage to the airplane": "Questa sezione è usata per trattare i danni visibili dell'aereo. Si prega di documentare ogni problema visibile prima di eseguire qualsiasi servizio",
  "Mark location": "Contrassegnare la posizione",
  "The end time must be later than the start time.": "L'ora di fine deve essere successiva a quella di inizio.",
  "Future time is not allowed": "Il tempo futuro non è permesso",
  "No timestamps configured for this flight type": "Nessun timestamp configurato per questo tipo di volo",
  "Set time": "Impostare il tempo",
  "Confirm remove?": "Confermare la rimozione?",
  "Change": "Cambia",
  "Change password?": "Cambiare la password?",
  "My profile": "Il mio profilo",
  "Destination": "Destinazione",
  "Origin": "Origine",
  "filter group not found": "gruppo filtro non trovato",
  "Date should not be before start date": "La data non deve essere precedente alla data di inizio",
  "Could not save data, please try again later": "Impossibile salvare i dati, riprova più tardi",
  "The current password you've entered is incorrect": "La password attuale che hai inserito non è corretta. Per favore ricontrolla e riprova.",
  "no user data": "nessun dato utente",
  "Helpdesk": "Helpdesk",
  "SENT": "INVIATO",
  "DENIED": "NEGATO",
  "DECLINED": "DECLINATO",
  "CONFIRMED": "CONFERMATO",
  "ACCEPTED": "ACCETTATO",
  "Set to now": "Impostare su ora",
  "Set": "Imposta",
  "Write your comment here, then press the green check mark": "Scrivi il tuo commento qui, poi premi il segno di spunta verde",
  "write new comment": "scrivere un nuovo commento",
  "Short remark": "Breve osservazione",
  "Filter group has not been updated": "Il gruppo di filtri non è stato aggiornato",
  "Save service registrations problem": "Salvare il problema delle registrazioni dei servizi",
  "and/or": "e/o",
  "Input a brief description here": "Inserisci una breve descrizione qui",
  "Right Tail Wing": "Ala di coda destra",
  "Left Tail Wing": "Ala di coda sinistra",
  "Tail": "Coda",
  "Body": "Corpo",
  "Neck": "Collo",
  "Nose": "Naso",
  "Right Engine": "Motore destro",
  "Left Engine": "Motore sinistro",
  "Right Wing": "Ala destra",
  "Left Wing": "Ala sinistra",
  "next": "prossimo",
  "Lost item": "Oggetto smarrito",
  "Damage": "Danno",
  "Filter group": "Gruppo filtro",
  "Archived": "Archiviato",
  "Message archived": "Messaggio archiviato",
  "Message unarchived": "Messaggio non archiviato",
  "No": "No",
  "Yes": "Sì",
  "to": "a",
  "Date range": "Intervallo di date",
  "Telex messages": "Messaggi telex",
  "No flight details information": "Nessuna informazione sui dettagli del volo",
  "No messages yet": "Nessun messaggio ancora",
  "messages yet": "messaggi ancora",
  "Flight not found": "Volo non trovato",
  "from": "da",
  "From": "Da",
  "Until": "Fino a",
  "Search": "Cerca",
  "registration complete": "registrazione completa",
  "Flight number/registration": "Numero di volo/registrazione",
  "Attachment": "Allegato",
  "Airline": "Compagnia aerea",
  "Username": "Nome utente",
  "Password": "Password",
  "Log in": "Entrare",
  "Open file": "Aprire il file",
  "Unavailable": "Non disponibile",
  "Read & Sign": "Leggere e firmare",
  "Available": "Disponibile",
  "Sign for understanding": "Segno di comprensione",
  "You can check your APPI code on Sunrise portal.": "Puoi controllare il tuo codice APPI sul portale Sunrise.",
  "We strongly recommend saving it in your smartphone phonebook": "Ti consigliamo vivamente di salvarlo nella rubrica del tuo smartphone in modo da avere sempre il codice APPI a portata di mano. Per salvare il codice, vai al tuo profilo Sunrise e scansiona il codice QR con il tuo smartphone.",
  "The APPI code is required when resetting your Aviapartner": "Il codice APPI è necessario per reimpostare la password del suo account Aviapartner in Sunrise o attraverso l'helpdesk Aviapartner. Può sempre trovare il suo codice APPI nel suo profilo in Sunrise.",
  "Extra shift request confirmed": "Richiesta di turno extra confermata",
  "This request is not valid anymore": "Questa richiesta non è più valida",
  "This message is marked as urgent": "Questo messaggio è contrassegnato come urgente",
  "A new password should be different than the current one": "Una nueva contraseña debe ser diferente a la actual",
  "Your password has expired. Please compose a new password and log in again.": "La tua password è scaduta. Per favore, componi una nuova password e accedi di nuovo.",
  "Your password and confirmation password do not match": "La tua password e la password di conferma non corrispondono.",
  "Your password must contain at least eight characters": "La tua password deve contenere almeno otto caratteri, almeno un numero e lettere sia minuscole che maiuscole.",
  "The username or password you've entered is incorrect": "Il nome utente o la password che hai inserito non sono corretti. Per favore ricontrolla e riprova. ",
  "Log out": "Disconnettersi",
  "Change password": "Cambiare la password",
  "Confirm new password": "Conferma la nuova password",
  "New password": "Nuova password",
  "Current password": "Password attuale",
  "is logged in": "è loggato",
  "Proceed to login": "Procedere al login",
  "Your password has been changed successfully!": "La tua password è stata cambiata con successo!",
  "Pick if its a damaged part or lost item": "Scegliere se si tratta di una parte danneggiata o di un articolo perso",
  "Pin on schema": "Pin su schema",
  "Specify where did you found the item": "Specifica dove hai trovato l'articolo",
  "Include nearby area": "Includere la zona circostante",
  "Add other location": "Aggiungi un'altra posizione",
  "Add row": "Aggiungere la fila",
  "Add line": "Aggiungere la linea",
  "Skip this step": "Salta questo passo",
  "Add description": "Aggiungere la descrizione",
  "Add location where item was found": "Aggiungere il luogo in cui è stato trovato l'oggetto",
  "Add storage location": "Aggiungere il luogo di deposito",
  "Briefly describe where the item will be stored for picking up.": "Descrivere brevemente il luogo in cui l'articolo sarà depositato per il ritiro.",
  "Photos": "Foto",
  "Take a photo": "Scattare una foto",
  "Close": "Chiudere",
  "FirebaseError: A document cannot be written because it exceeds the  maximum size allowed": "FirebaseError: Un documento non può essere scritto perché supera la dimensione massima consentita",
  "area": "zone",
  "Messages": "Messaggi",
  "Remove": "Rimuovi",
  "Are you sure you want to remove photo?": "Sei sicuro di voler rimuovere la foto?",
  "Comment": "Commento",
  "Included nearby area": "Inclusa la zona circostante",
  "Tap on area where damaged part is located": "Toccare l'area in cui si trova la parte danneggiata",
  "Conditional services": "Servizi condizionati",
  "SYSTEM SETTINGS": "IMPOSTAZIONI DI SISTEMA",
  "Flights": "Voli", "Weather": "Meteo",
  "Language": "Lingua",
  "Receive Extra shift requests": "Ricevi richieste di turni extra",
  "Default station": "Stazione predefinita",
  "Profile data wasn't updated!": "I dati del profilo non sono stati aggiornati",
  "Station": "Stazione",
  "Sign out": "Disconnettiti",
  "Cancel": "Annulla",
  "Stations": "Stazioni",
  "Profile": "Profilo",
  "Next 5 days": "Prossimi 5 giorni",
  "Today": "Oggi",
  "TODAY": "OGGI",
  "Humidity": "Umidità",
  "Wind": "Vento",
  "Precipitation": "Precipitazioni",
  "Pressure": "Pressione",
  "Clouds": "Nuvole",
  "Visibility": "Visibilità",
  "Sunrise": "Alba",
  "Flight details": "Dettagli del volo",
  "General info": "Informazioni generali",
  "Parking": "Parcheggio",
  "Runway": "Pista",
  "Gate": "Gate",
  "Aircraft type": "Tipo di aeromobile",
  "Operation flight number": "Numero di volo operativo",
  "Seats": "Posti a sedere",
  "Seat": "Sede",
  "Flight type": "Tipo di volo",
  "Registration": "Registrazione",
  "Call sign": "Call sign",
  "Crew signature": "FIRMA CREW",
  "Extra services": "Servizi extra",
  "extra service": "Servizi extra",
  "Comments": "Commenti",
  "Service registration": "Registrazione del servizio",
  "Message type": "TIPO DI MESSAGGIO",
  "All": "Tutti",
  "APPLY": "APPLICARE",
  "Filter": "Filtro",
  "Signed": "Firmato",
  "Internal communication": "Comunicazione interna",
  "Extra shift request": "Richiesta di turno extra",
  "Extra Shift Request": "Richiesta di turno extra",
  "Please, sign below": "Per favore, firmate qui sotto",
  "SAVE": "Salva",
  "save": "salva",
  "CLEAR": "Chiaro",
  "Service info saved": "Informazioni di servizio salvate",
  "Time registration": "Registrazione a tempo",
  "No instructions found": "Non sono state trovate istruzioni",
  "Instructions": "Istruzioni",
  "NOW": "ORA",
  "Sunset": "Tramonto",
  "The app has been updated": "L'applicazione è stata aggiornata",
  "End time should not be before start time": "L'ora di fine non dovrebbe essere prima dell'ora di inizio",
  "Start Time": "Ora di inizio",
  "Start": "Inizio",
  "End Time": "Tempo della fine",
  "End": "Fine",
  "Tomorrow": "Domani",
  "Yesterday": "Ieri",
  "No photo available": "Nessuna foto disponibile",
  "Show Photo": "Mostra foto",
  "Walkaround Arrival Checklist": "Checklist Walkaround in arrivo",
  "Inspections": "Ispezioni",
  "Walkaround Pre-Departure Checklist": "Checklist Walkaround pre-partenza",
  "Completed": "Completato",
  "Not completed": "Non completato",
  "Visual checks must be performed BEFORE GSE positioning": "I controlli visivi devono essere eseguiti prima del posizionamento GSE",
  "Visual checks must be performed AFTER GSE removal": "I controlli visivi devono essere eseguiti dopo la rimozione GSE",
  "Report any irregularities found upon arrival": "Segnala eventuali irregolarità trovate all'arrivo",
  "Report any other irregularities": "Segnala qualsiasi altra irregolarità",
  "If damage is found, mark it on the sketch": "Se si trova un danno, contrassegnalo sul disegno",
  "Checks on easily visible parts of the aircraft from the ground": "Controlli sulle parti dell'aeromobile facilmente visibili da terra:",
  "Please make sure you attached photos of irregularities": "Assicurati di allegare foto delle irregolarità",
  "Add description if there is a irregularity!": "Aggiungi una descrizione se c'è un'irregolarità!",
  "Sign form below to complete it": "Firma il form di cui sotto per completarlo:",
  "This form is signed": "Questo form è firmato",
  "Reopen": "Riaprire",
  "Add note": "Aggiungi nota...",
  "Hold Inspection on Arrival": "Ispezione della stiva arrivo",
  "Hold Inspection on Departure": "Ispezione della stiva partenza",
  "Flight information registration": "Registrazione delle informazioni sul volo",
};
